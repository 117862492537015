// Navbar Mixin
export default {
  data() {
    return {
      connectionsList: [],
    };
  },
  methods: {
    // Getting Request
    getConnectionRequests() {
      this.ajaxCallinprogress = true;
      this.$http
        .post("/notifications/get_connection_request", {
          skip: this.skip,
          limit: this.limit,
          search_key: this.search_key,
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.connectionsList = res.data.response;
            this.ajaxCallinprogress = false;
          }
        });
    },
  },
};
